export const en = {
  en: {
    from: `from`,
    to: `to`,

    all: `all`,
    none: `none`,
    live: `live`,
    virtual: `virtual`,
    slot: `slot`,
    casino: `casino`,
    mini: `mini`,
    sport: `sport`,

    bet_cancel: `bet cancel`,
    result: `result`,
    deleted: `deleted`,
    country: `country`,

    league_names: `league names`,
    game_name: `game name`,
    league: `league`,
    home_team: `home team`,
    away_team: `away team`,
    login_id: `login id`,
    login_name: `login name`,
    bet_amount: `bet amount`,
    win_amount: `win amount`,

    play_date: `play date`,
    result_date: `result date`,
    bet_date: `bet date`,

    //= bet item
    user: `user`,
    draw: `draw`,
    home: `home`,
    away: `away`,
    waiting: `waiting`,
    ongoing: `ongoing`,
    done: `done`,
    estimated_odds: `estimated odds`,
    actual_odds: `actual odds`,
    estimated_win: `estimated win`,
    win: `win`,
    status: `status`,
    cancelled: `cancelled`,
    betting_status: `bet status`
  }
}
