<template>
  <div class="wrap__contents bet-history--inner">
    <check-data-list
      v-if="isDataLoading || isEmpty"
      :isLoading="isDataLoading"
      :isEmpty="isEmpty"
    >
    </check-data-list>

    <div
      class="betslip__items"
      v-else
    >
      <betslip-card
        v-for="(slip, index) in bets"
        :key="index"
        :logs="slip"
        @update="update($event)"
        :showButtons="true"
      >
        <template v-if="slip.bet_info.length > 0">
          <bets-card
            v-for="(bets, idx) in slip.bet_info"
            :key="idx"
            :logs="bets"
            :slip="slip"
            @trigger="trigger"
          >
          </bets-card>
        </template>
      </betslip-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= components
import BetslipCard from '@/components/common/betslip/BetslipCard'
import BetsCard from '@/components/common/betslip/BetsCard'
import CheckDataList from '@/components/base/CheckDataList'


//= translation
import { translations } from '@/assets/js/translations/Bethistory'

export default {
  name: 'BetHistory',

  components: {
    BetslipCard,
    BetsCard,
    CheckDataList
  },

  data () {
    let params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'created_at',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      translations,
      params,
      selectedCountry : '',
      selectedGameName: '',
      selectedLeague: '',
      leaguesList : [],
      autoCompletes: [
        {
          key: 'country',
          data: ['...loading']
        },
        {
          key: 'game_name',
          data: ['...loading']
        },
        {
          key: 'league',
          data: ['...loading']
        }
      ],

      filterBy: [
        {
          value: 'login_id',
          display: 'login id'
        },
        {
          value: 'login_name',
          display: 'login name'
        },
        {
          value: 'bet_amount',
          display: 'bet amount'
        },
        {
          value: 'win_amount',
          display: 'win amount'
        },
        {
          value: 'home_team',
          display: 'home team'
        },
        {
          value: 'away_team',
          display: 'away team'
        },
      ],

      filterByAlt: [
        {
          value: 'login_id',
          display: 'login id'
        },
        {
          value: 'login_name',
          display: 'login name'
        },
        {
          value: 'bet_amount',
          display: 'bet amount'
        },
        {
          value: 'win_amount',
          display: 'win amount'
        }
      ],

      dateBy: [
        {
          value: 'play_date',
          display: 'play date'
        },
        {
          value: 'created_at',
          display: 'bet date'
        },
        {
          value: 'result_date',
          display: 'result date'
        }
      ],

      status: '',
      filter: '',

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false
    }
  },

  computed: {
    ...mapState('bet-history',
      ['betHistory', 'count']),
    ...mapState('game', {gameTypes: 'types'}),
    ...mapState('config', ['config']),
    ...mapState('bet-history-new', { bets: 'betHistory' }),
    ...mapState('bet-history-new', ['matches', 'formatted']),
  },

  watch: {
    '$route' (to) {
      this.search(this.generate(to.query))
    },

    filter () {
      this.resetParams()
      if (this.filter === '') {
        this.search()
      }
    },

    selectedGameName (v) {
      if (v ){
        this.generateLeagues({
          country : this.selectedCountry || '',
          game : v || ''
        }).then(r => {
          if (r.length) {
            this.leaguesList = r
          }
        })
      }
    }
  },

  async created () {
    this.search()
  },

  methods: {
    ...mapActions('bet-history-new', {
      getBetHistoryNew :'get',
      getBetList: 'getBetList'
    }),

    async trigger (q) {
      this.params = q || this.params

      this.params.country = this.selectedCountry || ''
      this.params.game = this.selectedGameName || ''
      this.params.league = this.selectedLeague || ''
      this.params.lang = this.lang || 'en'
      this.$router.push({ query: this.params })
    },

    async search (q) {
      this.$route.query.mgGame = JSON.parse(this.$route.query.mgGame)
      let { mgGame, mgIds, mgSel } = this.$route.query
      if (typeof mgIds === 'string' && mgIds.indexOf(',') !== -1) {
        mgIds = mgIds.split(',')
      }
      await this.getBetList({
        params: { mgSel, mgGame, mgIds }
      })

      this.isDataLoading = false
      this.showDataLoading = false
    }
  }
}
</script>
