export const jp = {
  jp: {
    from: `から`,
    to: `まで`,

    all: `全体`,
    none: `選択なし`,
    live: `リアルタイム スポーツ`,
    virtual: `仮想ゲーム`,
    slot: `スロットゲーム`,
    casino: `カジノゲーム`,
    mini: `ミニゲーム`,
    sport: `スポーツゲーム`,

    bet_cancel: `ベッティング取り消し`,
    result: `結果`,
    deleted: `削除`,
    country: `国`,

    league_names: `リーグ名`,
    game_name: `ゲーム名`,
    league: `リーグ名`,
    home_team: `ホーム チーム`,
    away_team: `アウェイ チーム`,
    login_id: `ID`,
    login_name: `ニックネーム`,
    bet_amount: `ベッティング金額`,
    win_amount: `獲得賞金`,

    play_date: `プレー日時`,
    result_date: `結果日時`,
    bet_date: `ベッティング日時`,

    //= bet item
    user: `ユーザー`,
    draw: `引き分け`,
    home: `ホーム チーム`,
    away: `アウェイ チーム`,
    waiting: `待機`,
    ongoing: `試合中`,
    done: `試合終了`,
    estimated_odds: `予想配当`,
    actual_odds: `実配当`,
    estimated_win: `予想 獲得賞金`,
    win: `勝ち`,
    status: `状態`,
    cancelled: `取り消し`,
    betting_status: `ベッティング状態`
  }
}
