export const kr = {
  kr: {
    bet_history: '배팅 내역',
    delete: '삭제',
    deleted_select: '선택삭제',
    check_all: '전체 선택',

    //= navigation
    all: '전체',
    sports: '스포츠',
    sports_kor: '스포츠 <small>(한국형)</small>',
    in_play: '인플레이',
    mini_games: '미니게임',
    results: '경기결과',
    boards: '게시판',
    support: '고객센터',
    promotions: '프로모션',
    virtual_sports: '가상 스포츠',
    casino: '카지노게임',
    slot_games: '슬롯게임',
    live_games: '라이브게임',
    token: '토큰게임',

    done: '완료',
    waiting: '대기',
    ongoing: '진행',
    cancelled: '취소',

    //= search / filter
    country: '국가',
    game_name: '게임명',
    league_names: '리그명',
    home_team: '홈팀',
    away_team: '원정팀',
    bet_amount: '배팅금액',
    win_amount: '당첨금액',
    play_date: '경기시간',
    result_date: '결과시간',
    from: '에서',
    to: '까지',
    date_type: '날짜 검색',

    bonus: '보너스 배당',

    //= bet slip
    bet_slip: '배팅 카트',
    home: '홈팀',
    tie: '무승부',
    draw: '무승부',
    away: '원정팀',
    bet_date: '배팅시간',

    match_winner: '경기 승자',
    empty_list: '목록이 없습니다.',

    special_games: '스페셜',
    crossbet: '크로스',

    buy: '매수',
    sell: '매도',
    ebuy: '매수',
    esell: '매도',
    wolf: '늑대',
    fox: '여우',
    bluew: '청이',
    redw: '홍이',
    blackb: '검정',
    redr: '빨강',
    nude: '누드',
    ok: '예',
    cancel: '배팅 취소',
    confirm: '확인',
    success: '성공'
  }
}
